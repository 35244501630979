var isPage = "";
let Img = {
	left: 0,
	top: 0
}
let imgs = [];
let Animate = {
	top: 0,
	show: 0
}
let animations = [];
let imgArry = [
	"/assets/images/index/slideshow/pc/img0.jpg",
	"/assets/images/index/slideshow/pc/img1.jpg",
	"/assets/images/index/slideshow/pc/img2.jpg",
	"/assets/images/index/slideshow/6.jpg",
];
function HtoZ(str) {
	return str.replace(/[A-Za-z0-9]/g, function(s) {
		return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
	});
}
var common = {
	init: ()=>{
		modal.init();
		common.makeVenue();
		//半角 > 全角
		if($html.classList.contains("safari")){
			$(".num").each(function(){
				let txt = $(this).text();
				txt = HtoZ(txt);
				txt = txt.replace("¥", '￥');
				txt = txt.replace(",", '<span class="dd">，</span>');
				$(this).html(txt);
			});
		}
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		imgs = [];
		$(".imgs li").each(function(i){
			let _i = object(Img);
			if(i%2 === 0){
				_i.top = Math.floor(Math.random()*10 + 10)*-1;
				_i.left = Math.floor(Math.random()*10 + 10)*-1;
			}
			imgs.push(_i);
		});
		common.resize();
		common.imageLoop();
		$("body").removeClass("disable");
		if(!isSP){
			//morph.top();
		}else{
			if(!isSmallScreen){
			//	morph.top();
			}
		}
		setInterval(function(){
			common.resize();
		}, 200);
		preload.startLoad(imgArry.length, imgArry).done(function(){
			setTimeout(function(){
				$("#main").addClass("active");
				slideshow.init();
			}, 400);
		});
		
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};

		$(document).off().on("scroll", function(){
			common.scrl();
		});

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	nav: ()=>{
		//share
		$(".sharewith li a").off().on("click", function(){
			$this = $(this);
			var idx = $(".sharewith li a").index(this);
			var url = $(this).attr("data-share");
			shareTxt(idx,url);
		});
		function shareTxt(num,shareUrl){
			var domain = "http://" + document.domain;
			var url 	= domain + shareUrl;
			if(num === 0){
				//Twitter
				var txt = $this.attr("data-text");
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(url) + "&amp;text=" + encodeURIComponent(txt),
					'twittersharedialog',
					'width=626,height=436');
			}else if(num === 1){
				//Facebook
				window.open(
					'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
					'facebooksharedialog',
					'width=626,height=436');
			}else{
				//LINE
				var txt = $this.attr("data-text") + "\n" + url;
				window.open(
					'http://line.me/R/msg/text/?' + encodeURIComponent(txt),
					'linesharedialog',
					'width=626,height=436');
			}
		}
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");

		if (window.matchMedia('all and (min-width : 864px)').matches) {
			isSmallScreen = false;
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			isSmallScreen = true;
		}
		if(isSmallScreen){
			$(".img_main").css({
				"width": "",
				"height": "",
				"transform": ""
			})
			let count = 0;
			$(".imgs li").each(function(i){
				if(count > $(".imgs li").length/2-1){
					count = 0;
				}
				$(this).css({
					"left": "",
					"top": imgs[count].top + "px"
				});
				count++;
			});
			$(".imgs ul").css("width", $(".imgs").eq(0).find("li").length*$(".imgs li").width() + "px");
		}else{
			let w = $(window).height()/768*1366;
			$(".img_main").css({
				"width": $(window).height()/768*1366 + "px",
				"height": $(window).height(),
				"transform": "translate3d(" + w/-2 + "px,0,0)"
			});
			$(".imgs li").each(function(i){
				$(this).css({
					"left": imgs[i].left + "px",
					"top": ""
				});
			});
			$(".imgs ul").css("width", "");
		}
		animations = [];
		$(".a").each(function(i){
			let _a 	= object(Animate);
			_a.top 	= Math.floor( $(this).offset().top );
			if($(this).attr("id") === "block_sns"){
				_a.show = Math.floor( $(this).offset().top + 100);
			}else{
				
			}
			_a.show = Math.floor( _a.top + window.innerHeight/4);
			animations.push(_a);
		});
		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	imageLoop: function(){
		let count = 0;
		move();

		function move(){
			count++;
			let d = 10;
			if(isSmallScreen){
				let w = $(".imgs ul").width();
				if(count >= w/2){
					count = 0;
				}
				$(".imgs ul").css({
					"transform":"translate3d(" + -count + "px, 0, 0)"
				});
				d = 33;
			}else{
				if(count > 5000){
					count = 0;
				}
				$(".imgs ul").css({
					"transform":"translate3d(0," + -count/100 + "%, 0)"
				});
			}
			setTimeout(function(){
				move();
			}, d);
		}
	},
	makeIO: function(){
		for(var i = 0; i < ioArry.length; i++){
			let option = {
				root 		: null,
				rootMargin 	: ioArry[i].margin,
				threshold 	: ioArry[i].threshold
			}
			let element = $(ioArry[i].target)
			const io = new IntersectionObserver(ioArry[i].change, option);
			$.each(element, function(index, el){
				io.observe(el);
			});
		}
	},
	scrollToPosition: (id,callback) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : "0px"
		},{
			"duration" 	: 1000,
			"easing" 	: ease,
			"complete"	: function(){
				if(flag){
					flag = false;
				}
			}
		});
	},
	scrl: () =>{
		var topD = $(document).scrollTop(),
			btmD = topD + $(window).height();

		if( topD >= $("#about").offset().top ){
			if( !$html.classList.contains("img_fix") ){
				$html.classList.add("img_fix");
			}
		}else{
			if( $html.classList.contains("img_fix") ){
				$html.classList.remove("img_fix");
			}
		}

		if( topD >= $("#info").offset().top ){
			if( !$html.classList.contains("white") ){
				$html.classList.add("white");
			}
		}else{
			if( $html.classList.contains("white") ){
				$html.classList.remove("white");
			}
		}

		let alen = animations.length;
		
		
		for(var a = 0; a < alen; a++){
			if(a === alen - 1){
				if(btmD >= animations[a].show){
					activeAnimate(a);
				}
			}else{
				if(btmD >= animations[a].show){
					activeAnimate(a);
				}
			}
		}
		function activeAnimate(a){
			let $blk = $(".a").eq(a);
			if(!$blk.hasClass("animate")){
				$blk.addClass("animate");
				setTimeout(function(){
					$blk.addClass("animated");
				},600);
			}
		}
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	},
	makeVenue: function(){
		let url = "/assets/json/venue.json";
		load.getJson(url, {}).done(function(json){
			let HTML = "";
			for(var i = 0; i < json.length; i++){
				let data = json[i];
				HTML += `<tr class="ttl_table">
							<th><p><span>${data.region}</span></p></th>
							<th><p><span>劇場名</span></p></th>
							<th><p><span>公開日</span></p></th>
							<th><p><span>電話番号</span></p></th>
						</tr>`;
				for(var j = 0; j < data.venues.length; j++){
					let d = data.venues[j];
					HTML += `<tr>
								<th><p><span>${d.area}</span></p></th>
								<td><p><a href="${d.link}" title="${d.name}" target="_blank"><span>${d.name}</span></a></p></td>
								<td><p><span>${d.date}</span></p></td>
								<td><p><span>${d.tel}</span></p></td>
							</tr>`
				}
			}
			$(".venue table tbody").html(HTML);
		});
	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
});

var modal = {
	cookie: "",
	init: function(){
		modal.cookie = $.cookie("kazuyosejima_movie_visited");
		if(modal.cookie === "1"){
			$("#modal_movie").remove();
		}else{
			modal.open();
			$.cookie("kazuyosejima_movie_visited", "1", { expires: 7 });
			modal.nav();
		}
	},
	nav: function(){
		$("#btn_close_modal").off().on("click", function(){
			modal.close();
		});
	},
	open: function(){
		$html.classList.add("modal");
		setTimeout(function(){
			$html.classList.add("show_modal");
		}, 50);
	},
	close: function(){
		$html.classList.remove("show_modal");
		setTimeout(function(){
			$html.classList.remove("modal");
		}, 600);
	}
}