var slideTimer;
var slideshow = {
	count: 0,
	max: 3,
	duration: 5000,
	hasFirst: true,
	init: function(){
		slideshow.hasFirst = true;
		slideshow.count = 0;
		slideshow.change();
		clearInterval(slideTimer);
		slideshow.change();
		slideTimer = setInterval(function(){
			slideshow.inc();
		}, slideshow.duration);
	},
	inc: function(){
		slideshow.count++;
		if(slideshow.count >= slideshow.max){
			slideshow.count = 0;
		}
		slideshow.change();
	},
	change: function(){
		var $slide;
		$slide = $(".slideshow ul.pc li");
		if( slideshow.hasFirst ){
			//$slide.find(".grid__item-img").removeClass("active");
			//$slide.eq(0).find(".grid__item-img").addClass("active");
			//$slide.eq(1).find(".grid__item-img").addClass("active");
		}else{
			$slide.removeClass("active");
			//$slide.find(".grid__item-img").removeClass("active");
			$slide.eq(slideshow.count).addClass("active");
			//$slide.eq(slideshow.count).find(".grid__item-img").addClass("active");
		}
		slideshow.hasFirst = false;
		/*
		if( slideshow.hasFirst ){
			setTimeout(function(){
				slideshow.hasFirst = false;
				$slide.eq(1).remove();
			}, 1000);
		}
		*/
	}
}